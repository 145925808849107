@if (notification$ | async; as notification) {
<app-notification-alert [type]="notification.type" [message]="notification.message"></app-notification-alert>
}
<div class="app-container">
  <app-navigation></app-navigation>
  <main class="main-content" role="main">
    <div class="mt-10">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
