import { Component } from '@angular/core';
import { NotificationService } from "./components/services";
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'maxim-vms-ui';

  constructor(private notificationService: NotificationService, private router: Router) { }
  notification$ = this.notificationService.getNotification$();

  currentView: 'visitor-table' | 'visitor-form' = 'visitor-table';

  // constructor(private notificationService: NotificationService) { }


  toggleVisitorForm() {
    this.currentView = this.currentView === 'visitor-table' ? 'visitor-form' : 'visitor-table';
    if (this.currentView == 'visitor-form') {
      this.router.navigate(['/create'])
    } else {
      this.router.navigate([''])
    }
    this.notificationService.hideNotification();
  }
}

