import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {DashboardComponent} from "./components/features/dashboard/dashboard.component";
import {NotificationAlertComponent} from "./components/shared/common/notification-alert/notification-alert.component";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {AuthInterceptor} from "./core/interceptors/request.interceptor";
import {CommonModule, DatePipe} from "@angular/common";
import { NavigationComponent } from "./components/shared/layout/navigation/navigation.component";
import { CreateVisitorFormComponent } from "./components/shared/visitor/create-visitor-form/create-visitor-form.component";
import { PendingVisitorTableComponent } from "./components/shared/visitor/pending-visitor-table/pending-visitor-table.component";
import { Router } from '@angular/router';

@NgModule({ declarations: [
        AppComponent,
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [CommonModule,
        BrowserModule,
        AppRoutingModule,
        DashboardComponent,
        NotificationAlertComponent,
        NavigationComponent,
        CreateVisitorFormComponent,
        PendingVisitorTableComponent], providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {

}
