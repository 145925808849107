import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  // {
  //   path: '',
  //   loadComponent: () => import('./components/index').then(com => com.DashboardComponent),
  // },
  {
    path: 'pending',
    loadComponent: () => import('./components/index').then(com => com.PendingVisitorTableComponent),
  },
  {
    path: 'approved',
    loadComponent: () => import('./components/index').then(com => com.ApprovedVisitorTableComponent),
  },
  {
    path: 'create',
    loadComponent: () => import('./components/index').then(com => com.CreateVisitorFormComponent),
  },
  {
    path: 'active',
    loadComponent: () => import('./components/index').then(com => com.ActiveVisitorTableComponent),
  },
  {
    path: 'all',
    loadComponent: () => import('./components/index').then(com => com.AllVisitorsComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}

